import React, { useEffect, useRef, useCallback, useState } from 'react';
import TwilioVideo from 'twilio-video';
import Popover from 'react-popover';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faShare, faShareSquare, faCog, faComment, faListAlt } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../janusBreakoutRoom/IconButton';
import TextButton from '../janusBreakoutRoom/TextButton';
import ParticipantPhoto from '../janusBreakoutRoom/ParticipantPhoto';
import StringUtil from '../../utils/string-util';
import Devices from './Devices';
import { store } from '../../store';
import { setOutputSpeakerId } from '../../store/actions';
import Logger from '../../utils/logger';

const flogger = new Logger('twilioBreakoutRoom:LocalParticipant');

export default function LocalParticipant({
  localParticipant,
  identity,
  photoUrl,
  isHost,
  isPresenter,
  hasScreensharePermission,
  roomIndex,
  audioEnabled,
  videoEnabled,
  screenShareEnabled,
  isRemoteScreenSharing,
  toggleAudio,
  toggleVideo,
  toggleScreenShare,
  isTechSupportRoom,
  supportRequested,
  onRequestSupport,
  startTime,
  showParticipantList,
  onToggleParticipantList,
  hideAttendeeDetails,
}) {
  const localParticipantVideoRef = useRef(null);
  const localParticipantAudioRef = useRef(null);
  const [showDevices, setShowDevices] = useState(false);
  const [timer, setTimer] = useState('');
  const [isMediaReady, setIsMediaReady] = useState(false);

  useEffect(() => {
    if (localParticipant) {
      localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track.kind === 'video') {
          track.attach(localParticipantVideoRef.current);
        } else {
          track.attach(localParticipantAudioRef.current);
        }
      });

      localParticipantVideoRef.current.addEventListener('loadeddata', () => {
        setIsMediaReady(true);
      });
    }
  }, [localParticipant]);

  useEffect(() => {
    const savedAudioOutDevice = localStorage.getItem('audioOutput');
    if (savedAudioOutDevice) {
      const parsed = JSON.parse(savedAudioOutDevice);
      flogger.info('saved output speaker found', parsed);
      store.dispatch(setOutputSpeakerId(parsed.deviceId));
    }
  }, []);

  const handleDeviceChange = useCallback(
    async (deviceId, kind) => {
      if (!localParticipant) return;
      const logger = flogger.deeper('handleDeviceChange');
      logger.info({deviceId, kind});
      if (!localParticipant) {
        logger.info('Local participant not found');
        return;
      }

      let localTracks = [];
      switch (kind) {
        case 'audio':
          localTracks = localParticipantAudioRef.current.srcObject.getTracks();
          break;
        case 'video':
          localTracks = localParticipantVideoRef.current.srcObject.getTracks();
          break;
        default:
          break;
      }

      for (let i = 0; i < localTracks.length; i++) {
        await localTracks[i].stop();
      }
      localParticipant.unpublishTracks(localTracks);
      if (kind === 'audio') {
        logger.info('updating audio');
        const localAudioTrack = await TwilioVideo.createLocalAudioTrack({ deviceId });
        logger.info('local audio track:', {deviceId, localAudioTrack});
        localAudioTrack.attach(localParticipantAudioRef.current);
        logger.info('attached to audio ref', {ref: localParticipantAudioRef.current});
        localParticipant.publishTrack(localAudioTrack);
        logger.info('published audio track');
      } else if (kind === 'video') {
        logger.info('updating video');
        const localVideoTrack = await TwilioVideo.createLocalVideoTrack({ deviceId });
        logger.info('local video track', {deviceId, localVideoTrack});
        localVideoTrack.attach(localParticipantVideoRef.current);
        logger.info('attached to video ref', {ref: localParticipantVideoRef.current});
        localParticipant.publishTrack(localVideoTrack);
        logger.info('published video track');
      } else if (kind === 'speaker') {
        logger.info('updating speaker');
        store.dispatch(setOutputSpeakerId(deviceId));
      } else {
        logger.error('unhandled device kind', kind);
      }
    },
    [localParticipant],
  );

  const _startTimer = () => {
    if (!startTime) return;

    const now = new Date().getTime();
    const start = startTime.getTime();
    let diff = Math.round((now - start) / 1000);
    const h = Math.floor(diff / (60 * 60));
    diff -= h * 60 * 60;
    const m = Math.floor(diff / 60);
    diff -= m * 60;
    const s = diff;
    setTimer(`${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`);
  };

  useEffect(() => {
    const _timer = setInterval(_startTimer, 1000);
    return () => {
      if (_timer) clearInterval(_timer);
    };
  }, [startTime]);

  const name = StringUtil.getDisplayName(identity, hideAttendeeDetails);

  return (
    <>
      <div style={{ position: 'relative', display: 'flex', width: 320, height: 180 }}>
        <video ref={localParticipantVideoRef} style={{ width: '100%' }} playsInline autoPlay disablePictureInPicture />
        {videoEnabled === false && photoUrl && <ParticipantPhoto photoUrl={photoUrl} />}
        <audio ref={localParticipantAudioRef} autoPlay muted />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0,0,0,0.2)',
            opacity: isMediaReady ? 1 : 0,
          }}
        >
          <IconButton onIcon={faMicrophone} offIcon={faMicrophoneSlash} value={audioEnabled} onChange={toggleAudio} tooltip='Mic' />
          <IconButton onIcon={faVideo} offIcon={faVideoSlash} value={videoEnabled} onChange={toggleVideo} tooltip='Camera' />
          {(isHost || isPresenter || hasScreensharePermission) && !isRemoteScreenSharing ? (
            <IconButton offIcon={faShare} onIcon={faShareSquare} value={screenShareEnabled} onChange={toggleScreenShare} tooltip='Share Screen' />
          ) : null}
          {isTechSupportRoom && isHost ? (
            <IconButton
              onIcon={faComment}
              offIcon={faListAlt}
              value={showParticipantList}
              onChange={onToggleParticipantList}
              tooltip={showParticipantList ? 'Show chat' : 'Show participants'}
            />
          ) : null}
          <Popover
            isOpen={showDevices}
            body={<Devices onDeviceChange={handleDeviceChange} />}
            onOuterAction={() => setShowDevices(false)}
            tipSize={6}
            place='right'
            style={{ fill: '#1e272f', zIndex: 99999 }}
          >
            <IconButton onIcon={faCog} offIcon={faCog} value={true} onChange={() => setShowDevices(!showDevices)} tooltip='Device Settings' />
          </Popover>
          {!isTechSupportRoom && (
            <TextButton
              onText={'HELP'}
              offText={'HELP'}
              customClass={supportRequested ? 'pulse' : null}
              value={supportRequested}
              onChange={onRequestSupport}
              tooltip='Tech Support'
            />
          )}
        </div>
      </div>
      {roomIndex ? (
        <div
          style={{
            color: '#a9aaab',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 40,
            minHeight: 40,
            padding: '0 8px',
          }}
        >
          <div>{isTechSupportRoom ? `Tech Support ${roomIndex - 15}` : `Breakout Room ${roomIndex}`}</div>
          <span style={{ margin: '0 5px', color: '#a9aaab', fontSize: 14 }}>{timer}</span>
        </div>
      ) : null}
      <div
        style={{
          color: '#a9aaab',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 40,
          minHeight: 40,
          padding: '0 8px',
        }}
      >
        <span style={{ marginRight: 4 }}>{name}</span>
        {isHost ? <span>{`(Host)`}</span> : null}
        {isPresenter ? <span>{`(Presenter)`}</span> : null}
      </div>
    </>
  );
}
