import React, { useState, useEffect } from 'react';
import Select from '../../common/Select';
import MediaDeviceUtil from '../../utils/media-device-util';
import '../../common/MediaDevices/MediaDevices.css';
import Storage from '../../utils/storage';
import { disableSwitchSpeaker, isSafari } from '../../utils/browser-util';

const localStorage = Storage.getLocalStorage();

function Devices({ onDeviceChange }) {
  const [_videoDevices, setVideoDevices] = useState(null);
  const [_audioInputDevices, setAudioInputDevices] = useState(null);
  const [_audioOutputDevices, setAudioOutputDevices] = useState(null);
  const [_selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [_selectedAudioInputDevice, setSelectedAudioInputDevice] = useState('');
  const [_selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState('');

  async function updateMediaDevices() {
    const devices = await MediaDeviceUtil.getAvailableDevices();
    const videoDevices = devices.filter((v) => v.kind === 'videoinput' && v.deviceId !== 'default');
    const audioInputDevices = devices.filter((v) => v.kind === 'audioinput' && v.deviceId !== 'default');
    const audioOutputDevices = devices.filter((v) => v.kind === 'audioinput' && v.deviceId !== 'default');

    setVideoDevices(videoDevices);
    setAudioInputDevices(audioInputDevices);
    setAudioOutputDevices(audioOutputDevices);
  }

  useEffect(() => {
    (async () => {
      const availableDevices = await MediaDeviceUtil.getAvailableDevices();

      const filteredVideoDevices = availableDevices.filter((d) => d.kind === 'videoinput' && d.deviceId !== 'default');
      const filteredAudioInputDevices = availableDevices.filter((d) => d.kind === 'audioinput' && d.deviceId !== 'default');
      const filteredAudioOutputDevices = availableDevices.filter((d) => d.kind === 'audiooutput' && d.deviceId !== 'default');

      setVideoDevices(filteredVideoDevices);
      setAudioInputDevices(filteredAudioInputDevices);
      setAudioOutputDevices(filteredAudioOutputDevices);

      const savedVideoDevice = localStorage.getItem('videoInput');
      const savedAudioInputDevice = localStorage.getItem('audioInput');
      const savedAudioOutDevice = localStorage.getItem('audioOutput');

      if (savedVideoDevice) {
        setSelectedVideoDevice(JSON.parse(savedVideoDevice));
      }

      if (savedAudioInputDevice) {
        setSelectedAudioInputDevice(JSON.parse(savedAudioInputDevice));
      }

      if (savedAudioOutDevice) {
        setSelectedAudioOutputDevice(JSON.parse(savedAudioOutDevice));
      }
    })();

    window.navigator.mediaDevices.addEventListener('devicechange', updateMediaDevices);
    return () => {
      window.navigator.mediaDevices.removeEventListener('devicechange', updateMediaDevices);
    };
  }, []);

  function _onDeviceChange(option, type) {
    if (type === 'video') {
      localStorage.setItem(`videoInput`, JSON.stringify(option));
      setSelectedVideoDevice(option);
      onDeviceChange(option.deviceId, type);
    } else if (type === 'audio') {
      localStorage.setItem(`audioInput`, JSON.stringify(option));
      setSelectedAudioInputDevice(option);
      onDeviceChange(option.deviceId, type);
    } else if (type === 'speaker') {
      localStorage.setItem(`audioOutput`, JSON.stringify(option));
      setSelectedAudioOutputDevice(option);
      onDeviceChange(option.deviceId, type);
    }
  }

  return (
    <div className='settings'>
      <div className='device-list-container'>
        <div className='label'>Camera</div>
        <Select
          list={_videoDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={(option) => _onDeviceChange(option, 'video')}
          selected={_selectedVideoDevice && _selectedVideoDevice.value}
          currentOption={_selectedVideoDevice && _selectedVideoDevice.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Microphone</div>
        <Select
          list={_audioInputDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={(option) => _onDeviceChange(option, 'audio')}
          selected={_selectedAudioInputDevice && _selectedAudioInputDevice.value}
          currentOption={_selectedAudioInputDevice && _selectedAudioInputDevice.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Speaker</div>
        <Select
          list={_audioOutputDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={(option) => _onDeviceChange(option, 'speaker')}
          selected={_selectedAudioOutputDevice && _selectedAudioOutputDevice.value}
          currentOption={_selectedAudioOutputDevice && _selectedAudioOutputDevice.label}
          containerStyle={{ width: '100%' }}
          small
          disabled={isSafari() || disableSwitchSpeaker}
        />
      </div>
    </div>
  );
}

export default Devices;
