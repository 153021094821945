import React, { useEffect, useRef } from 'react';
import ParticipantPhoto from '../janusBreakoutRoom/ParticipantPhoto';
import StringUtil from '../../utils/string-util';
import { useSelector } from 'react-redux';
import Logger from '../../utils/logger';

const flogger = new Logger('twilioBreakoutRoom:RemoteParticipant');

export default function RemoteParticipant({
  participant,
  width,
  height,
  margin,
  onRemoteScreenshareTrack,
  onVideoTrackStateChange,
  onAudioTrackStateChange,
  remoteTracksState,
  showSharedContent,
  hideAttendeeDetails,
}) {
  const remoteParticipantImg = useRef(null);
  const remoteParticipantVideoRef = useRef(null);
  const remoteParticipantAudioRef = useRef(null);
  const outputSpeakerId = useSelector((state) => state.app.outputSpeakerId);

  function updateSpeaker() {
    const audio = remoteParticipantAudioRef.current;
    if (audio && audio.setSinkId && outputSpeakerId) {
      if (audio.readyState >= HTMLMediaElement.HAVE_FUTURE_DATA) {
        flogger.info('updating speaker', audio, outputSpeakerId);
        audio.setSinkId(outputSpeakerId);
      } else {
        flogger.info('Waiting for audio to be ready to change speaker');
        audio.addEventListener('canplay', function tmp() {
          flogger.info('Updating speaker after audio loaded');
          audio.setSinkId(outputSpeakerId);
          audio.removeEventListener('canplay', tmp);
        });
      }
    }
  }

  useEffect(() => {
    updateSpeaker();
  }, [outputSpeakerId, remoteParticipantAudioRef.current]);

  useEffect(() => {
    if (!participant) return;

    const handleTrackState = (track) => {
      track.on('disabled', () => {
        if (track.kind === 'video') {
          remoteParticipantImg.current.style.display = 'block';
          track.detach(remoteParticipantVideoRef.current);
          onVideoTrackStateChange({ participant: participant.sid, state: false });
        } else {
          track.detach(remoteParticipantAudioRef.current);
          onAudioTrackStateChange({ participant: participant.sid, state: false });
        }
      });

      track.on('enabled', () => {
        if (track.kind === 'video') {
          remoteParticipantImg.current.style.display = 'none';
          track.attach(remoteParticipantVideoRef.current);
          onVideoTrackStateChange({ participant: participant.sid, state: true });
        } else {
          track.attach(remoteParticipantAudioRef.current);
          onAudioTrackStateChange({ participant: participant.sid, state: true });
        }
      });

      if (track.kind === 'video') {
        if (track.isEnabled) {
          remoteParticipantImg.current.style.display = 'none';
          track.attach(remoteParticipantVideoRef.current);
        }
        onVideoTrackStateChange({ participant: participant.sid, state: track.isEnabled });
      }
      if (track.kind === 'audio') {
        if (track.isEnabled) {
          track.attach(remoteParticipantAudioRef.current);
        }
        onAudioTrackStateChange({ participant: participant.sid, state: track.isEnabled });
      }
    };

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        const track = publication.track;
        handleTrackState(track);
      }

      publication.on('subscribed', handleTrackState);
      publication.on('unsubscribed', handleTrackState);
    });

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        if (track.name === 'screenshare') {
          onRemoteScreenshareTrack(track);
        } else {
          remoteParticipantImg.current.style.display = 'none';
          track.attach(remoteParticipantVideoRef.current);
          onVideoTrackStateChange({ participant: participant.sid, state: track.isEnabled });
        }
      } else {
        track.attach(remoteParticipantAudioRef.current);
        onAudioTrackStateChange({ participant: participant.sid, state: track.isEnabled });
      }
    };
    participant.on('trackSubscribed', trackSubscribed);

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        if (track.name === 'screenshare') {
          onRemoteScreenshareTrack(null);
        } else {
          if (remoteParticipantImg.current) remoteParticipantImg.current.style.display = 'block';
          track.detach(remoteParticipantVideoRef.current);
          onVideoTrackStateChange({ participant: participant.sid, state: track.isEnabled });
        }
      } else {
        track.detach(remoteParticipantAudioRef.current);
        onAudioTrackStateChange({ participant: participant.sid, state: track.isEnabled });
      }
    };
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      participant.tracks.forEach((publication) => {
        if (publication.track) {
          publication.track.detach().forEach((element) => element.remove());
        }
        publication.off('subscribed', handleTrackState);
        publication.off('unsubscribed', handleTrackState);
      });
      participant.off('trackSubscribed', trackSubscribed);
      participant.off('trackUnsubscribed', trackUnsubscribed);
    };
  }, [participant]);

  const photoUrl = remoteTracksState ? remoteTracksState.photoUrl : null;
  const name = StringUtil.getDisplayName(participant.identity, hideAttendeeDetails);

  return (
    <div style={{ position: 'relative', width, height, margin, color: '#fff', background: '#000' }}>
      <video
        ref={remoteParticipantVideoRef}
        playsInline
        autoPlay
        disablePictureInPicture
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      />
      <audio ref={remoteParticipantAudioRef} autoPlay />
      <div ref={remoteParticipantImg} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, display: 'none' }}>
        <ParticipantPhoto photoUrl={photoUrl} />
      </div>
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2,
          backgroundColor: 'rgba(0,0,0,0.5)',
          padding: '5px 10px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: showSharedContent ? '0.8vw' : '1vw',
          textAlign: 'center',
        }}
      >
        {name}
      </div>
    </div>
  );
}
