export default class Logger {
  constructor(prefix) {
    this.prefix = prefix;
  }

  _log(func, ...args) { return func(this.prefix, ...args); }
  info(...args) { return this._log(console.info, ...args); }
  error(...args) { return this._log(console.error, ...args); }
  warn(...args) { return this._log(console.warn, ...args); }

  deeper(prefix) {
    return new Logger(`${this.prefix}:${prefix}`);
  }
}
